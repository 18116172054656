import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout"
      }}>{`CSS Grid Layout`}</a>{` ist ein zweidimensionales,
rasterbasiertes Layout-System für Websites. Es erleichtert die Anordnung von Inhalten in Zeilen und Spalten sowie die
Erstellung responsiver Layouts.`}</p>
    <p>{`Wer mit Grid-Layouts vertraut ist, kennt wahrscheinlich das folgende Problem: Ein Grid-Container kann in einem anderen
Grid platziert werden, um ein `}<a parentName="p" {...{
        "href": "https://gridbyexample.com/examples/example21/"
      }}>{`verschachteltes Grid-Layout`}</a>{` zu erzeugen.
Leider passt sich der Inhalt des untergeordneten Grids nicht automatisch an das übergeordnete Grid an. Aber keine Sorge!
Wir können dieses Problem mit dem `}<InlineCode mdxType="InlineCode">{`subgrid`}</InlineCode>{` Feature lösen!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABUai0VDULj//EABkQAQEBAQEBAAAAAAAAAAAAAAECAwAREv/aAAgBAQABBQIzfkjMhselrTpzPJgk/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/ARLW/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPwGbY//EABoQAAMBAAMAAAAAAAAAAAAAAAABEQIQIUH/2gAIAQEABj8Cvg9N2F0nSafXEP/EABwQAAICAgMAAAAAAAAAAAAAAAABESExQVFxof/aAAgBAQABPyFxHVds6QUQLCHMC1XWoHXivRJyP//aAAwDAQACAAMAAAAQ1w//xAAWEQEBAQAAAAAAAAAAAAAAAAAAAVH/2gAIAQMBAT8QhaV//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxBGJg//xAAfEAEAAgEDBQAAAAAAAAAAAAABABEhMUFhgaHB4fD/2gAIAQEAAT8QqzqqKFVrDWgWhDp49xtHZIwcASyDjQDBt2jISzWT5piWEab5J//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ein Schachbrett mit einem Raster aus Zeilen und Spalten.",
          "title": "Ein Schachbrett mit einem Raster aus Zeilen und Spalten.",
          "src": "/static/ce426dbc3bdbc129d8393283007c4456/e5166/pexels-rodnae-productions-chess-board.jpg",
          "srcSet": ["/static/ce426dbc3bdbc129d8393283007c4456/f93b5/pexels-rodnae-productions-chess-board.jpg 300w", "/static/ce426dbc3bdbc129d8393283007c4456/b4294/pexels-rodnae-productions-chess-board.jpg 600w", "/static/ce426dbc3bdbc129d8393283007c4456/e5166/pexels-rodnae-productions-chess-board.jpg 1200w", "/static/ce426dbc3bdbc129d8393283007c4456/b17f8/pexels-rodnae-productions-chess-board.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © RODNAE Productions / pexels.com`}</em></p>
    <p>{`Die Subgrid-Funktion macht das Grid-Layout noch mächtiger und ermöglicht es, perfekt ausgerichtete Layouts zu erstellen.
Ich habe eine Demo für einen typischen Anwendungsfall vorbereitet. Schauen wir uns an, wie das Ganze funktioniert.`}</p>
    <h2>{`Die Grundlagen des Grid-Layouts`}</h2>
    <p>{`Das Grid-Layout-System ist in der Spezifikation des `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-grid-2/"
      }}>{`CSS Grid Layout Moduls`}</a>{` definiert:`}</p>
    <blockquote lang="en">
    A <BoldText mdxType="BoldText">two-dimensional grid-based layout system</BoldText>, optimized for user interface design. In the grid layout model,
    the children of a grid container can be positioned into arbitrary slots in a predefined flexible or fixed-size layout grid.
    </blockquote>
    <p>{`Ihr könnt ein HTML-Element mit der CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`display: grid`}</InlineCode>{` in einen Grid-Container verwandeln.
Mit den Eigenschaften `}<InlineCode mdxType="InlineCode">{`grid-template-rows`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`grid-template-columns`}</InlineCode>{` könnt ihr
die Zeilen und Spalten definieren.`}</p>
    <p>{`Es gibt zahlreiche Möglichkeiten, ein Raster zu definieren und es mit Inhalten zu füllen. Alle möglichen Einstellungen und Szenarien
aufzulisten, würde den Rahmen dieses Artikels sprengen. Wenn ihr mehr erfahren möchtet, kann ich die Tutorials
`}<a parentName="p" {...{
        "href": "https://css-tricks.com/snippets/css/complete-guide-grid/"
      }}><span lang="en">{`„A Complete Guide to CSS Grid“`}</span></a>{` und
`}<a parentName="p" {...{
        "href": "https://learncssgrid.com/"
      }}><span lang="en">{`„Learn CSS Grid“`}</span></a>{` empfehlen.`}</p>
    <h2>{`Was ist ein Subgrid und wozu ist es gut?`}</h2>
    <p>{`Das Subgrid-Feature wurde im `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-grid-2/#subgrids"
      }}>{`CSS Grid Layout Modul Level 2`}</a>{` hinzugefügt. Die
Spezifikation besagt:`}</p>
    <blockquote lang="en">
    A nested grid can defer the definition of its rows and/or columns to its parent grid container, making it a subgrid.
    In this case, the grid items of the subgrid participate in sizing the parent grid, allowing the contents of both grids to align.
    </blockquote>
    <p>{`Um ein Subgrid zu definieren, setzt ihr einfach den Wert `}<InlineCode mdxType="InlineCode">{`subgrid`}</InlineCode>{` für die
Eigenschaft `}<InlineCode mdxType="InlineCode">{`grid-template-rows`}</InlineCode>{` oder `}<InlineCode mdxType="InlineCode">{`grid-template-columns`}</InlineCode>{`. Das war's! Das
verschachtelte Grid verwendet nun die vom übergeordneten Grid definierten Spalten und/oder Zeilen und richtet seinen Inhalt
entsprechend aus.`}</p>
    <h2>{`Demo: Punk API Bier-Liste`}</h2>
    <p>{`Ich habe eine `}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/punk-api-beer-list"
      }}>{`React-Demo`}</a>{` erstellt, die eine Liste von Bieren in einem Raster
mit drei gleichgroßen Spalten anzeigt. Jede Zelle enthält Informationen über ein bestimmtes Bier. Seht euch die
`}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/punk-api-beer-list/"
      }}>{`Live-Version hier`}</a>{` an (am besten auf einem Desktop-Bildschirm).`}</p>
    <iframe src="https://alexlehner86.github.io/punk-api-beer-list/" title="Demo-Seite: Bier-Liste mit Subgrid-Feature" loading="lazy"></iframe>
    <h3>{`Der übergeordnete Grid-Container`}</h3>
    <p>{`Die Demo verwendet eine ungeordnete Liste als übergeordneten Grid-Container. Hier ist der
`}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/introducing-jsx.html"
      }}>{`JSX-Code`}</a>{`:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<ul className={styles.beerGrid}>
    {items.map(item => (
        <li key={item.id}>
            <BeerItemDetails item={item} />
        </li>
    ))}
</ul>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In meinem CSS-Code verwende ich die Funktionen `}<InlineCode mdxType="InlineCode">{`repeat`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`minmax`}</InlineCode>{`, um ein Raster
mit drei Spalten und einer variablen Anzahl von Zeilen zu erstellen. Jede Spalte ist gleich groß und hat eine Mindestbreite
von `}<InlineCode mdxType="InlineCode">{`15rem`}</InlineCode>{`.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`ul.beerGrid {
    display: grid;
    gap: 2.5rem;
    grid-template-columns: repeat(3, minmax(15rem, 1fr));
    grid-template-rows: auto;
    list-style: none;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3>{`Die Bier-Infokarten zu Subgrids machen`}</h3>
    <p>{`Jedes Listenelement wird mit der React-Komponente `}<InlineCode mdxType="InlineCode">{`BeerItemDetails`}</InlineCode>{` gerendert. Diese Komponente besteht aus
einem Kopfbereich (`}<InlineCode mdxType="InlineCode">{`hgroup`}</InlineCode>{` Element) und einem Detailbereich (`}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` Element):`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<hgroup className={styles.heading}>
    <h2>{item.name}</h2>
    <p>{item.tagline}</p>
</hgroup>
<div className={styles.flexRow}>
    <!-- details -->
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In meinem CSS-Code definiere ich die Listenelemente als Grid-Container mit zwei Zeilen (`}<InlineCode mdxType="InlineCode">{`grid-row: span 2`}</InlineCode>{`).
Die Deklaration `}<InlineCode mdxType="InlineCode">{`grid-template-rows: subgrid`}</InlineCode>{` weist den Browser an, die Kopfzeile und den Detailbereich jeder
Bier-Infokarte aufeinander auszurichten, wenn diese in derselben Zeile platziert werden.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`ul.beerGrid li {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 2;
    gap: 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3>{`Einfache Entwicklung mit CSS Grid Inspector`}</h3>
    <p>{`Ich habe den `}<a parentName="p" {...{
        "href": "https://firefox-source-docs.mozilla.org/devtools-user/page_inspector/how_to/examine_grid_layouts/"
      }}>{`CSS Grid Inspector`}</a>{`
(Firefox DevTools) intensiv genutzt. Dieses geniale Werkzeug hebt die Zeilen und Spalten eures Rasters hervor und macht es einfacher
zu verstehen, wie das übergeordnete Grid und seine Subgrids zusammenpassen.`}</p>
    <p>{`Die Chrome DevTools enthalten ein `}<a parentName="p" {...{
        "href": "https://developer.chrome.com/docs/devtools/css/grid/"
      }}>{`ähnliches Tool`}</a>{`. Aber als leidenschaftlicher
Firefox-Nutzer musste ich natürlich das Firefox-Tool als erstes nennen. 😉`}</p>
    <h2>{`Browser-Unterstützung und progressive Verbesserung`}</h2>
    <p>{`Zum Zeitpunkt der Veröffentlichung dieses Beitrags `}<a parentName="p" {...{
        "href": "https://caniuse.com/css-subgrid"
      }}>{`unterstützen nur Safari und Firefox`}</a>{` das
Subgrid-Feature. Aber keine Sorge! Ihr müsst nicht warten, bis Chrome und Edge aufholen.`}</p>
    <p>{`Wendet das Prinzip der `}<a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/Progressive_Verbesserung"
      }}>{`progressiven Verbesserung`}</a>{` an, um ein Basis-Layout
bereitzustellen und gleichzeitig die Vorteile des Subgrid-Features in unterstützenden Browsern zu nutzen. Dazu verwenden
wir `}<InlineCode mdxType="InlineCode">{`@supports`}</InlineCode>{`, um die Browserunterstützung zu prüfen, bevor wir den Wert `}<InlineCode mdxType="InlineCode">{`subgrid`}</InlineCode>{`
setzen:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@supports (grid-template-rows: subgrid) {
    ul.beerGrid li {
        display: grid;
        grid-template-rows: subgrid;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/css-grid-2/"
        }}>{`CSS Grid Layout Modul Level 2 (Spezifikation)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://css-tricks.com/snippets/css/complete-guide-grid/"
        }}><span lang="en">{`„A Complete Guide to CSS Grid“`}</span>{` (CSS Tricks)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gridbyexample.com/"
        }}><span lang="en">{`„Grid by Example“`}</span>{` (Rachel Andrew)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://learncssgrid.com/"
        }}><span lang="en">{`„Learn CSS Grid“`}</span>{` (Jonathan Suh)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ishadeed.com/article/learn-css-subgrid/"
        }}><span lang="en">{`„Learn CSS Subgrid“`}</span>{` (Ahmad Shadeed)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      